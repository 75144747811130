<template>
  <b-table
    :items="gems"
    :fields="gemsFields"
    bordered
  >
    <template #cell(rarity)="data">
      <span :class="getRarityClass(data.item.rarity)">{{ formatRarity(data.item.rarity) }}</span>
    </template>
    <template #cell(status)="data">
      <span :class="getStatusClass(data.item.status)">{{ data.item.status }}</span>
    </template>
    <template #cell(condition)="data">
      <span v-if="data.item.condition">
        {{ formatCondition(data.item.condition) }}
      </span>
      <span v-else>—</span>
    </template>
  </b-table>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { gemsFields } from './inventoryData'

export default {
  name: 'InventoryGems',
  components: {
    BTable,
  },
  props: {
    gems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    gemsFields,
  }),
  methods: {
    getRarityClass(rarity) {
      const rarityClasses = {
        c: 'badge bg-secondary', // Common
        s: 'badge bg-success', // Special
        r: 'badge bg-primary', // Rare
        er: 'badge bg-warning', // Epic Rare
        ur: 'badge bg-danger', // Ultra Rare
      }
      return rarityClasses[rarity] || 'badge bg-dark'
    },
    formatRarity(rarity) {
      const rarityNames = {
        c: 'Common',
        s: 'Special',
        r: 'Rare',
        er: 'Epic Rare',
        ur: 'Ultra Rare',
      }
      return rarityNames[rarity] || rarity
    },
    getStatusClass(status) {
      return status === 'available' ? 'text-success' : 'text-danger'
    },
    formatCondition(condition) {
      return `Complete ${condition.count} ${condition.subtype} task(s)`
    },
  },
}
</script>

<style>
</style>
