<template>
  <b-card>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      {{ title }}
    </b-card-title>

    <!-- form -->
    <validation-observer ref="banValidations">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
      >

        <b-form-group
          label="Reason"
          label-for="reason"
        >
          <validation-provider
            #default="{ errors }"
            name="reason"
            rules="required"
          >
            <b-form-select
              v-model="reason"
              name="reason"
              :options="reasons"
              @change="comment = ''"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-if="reason === 'other'"
          label="Comment"
          label-for="comment"
        >
          <b-form-input
            id="monthly-out"
            v-model="comment"
            type="text"
            name="comment"
          />
        </b-form-group>

        <b-button
          block
          type="submit"
          variant="primary"
          class="mt-2"
          :disabled="requestInProgress || (reason === 'other' && !comment) || !reason"
          @click="save"
        >
          Ban
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BCardTitle, BForm, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import validationError from '@/mixins/validationError'
import waitRequest from '@/mixins/waitRequest'
// eslint-disable-next-line
import { required } from '@validations'

export default {
  name: 'EditCeFiLimitsModal',
  components: {
    BButton,
    BCard,
    BFormGroup,
    BForm,
    ValidationProvider,
    BFormInput,
    BCardTitle,
    BFormSelect,
    ValidationObserver,
  },
  mixins: [validationError, waitRequest],
  props: {
    title: {
      type: String,
      default: 'Ban User',
    },
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ['close', 'ban'],
  data() {
    return {
      comment: '',
      reason: '',
      reasons: [
        {
          text: 'Bot',
          value: 'bot',
        },
        {
          text: 'Multi account',
          value: 'multiaccount',
        },
        {
          text: 'Abuse',
          value: 'abuse',
        },
        {
          text: 'AML',
          value: 'aml',
        },
        {
          text: 'Other',
          value: 'other',
        },
      ],
    }
  },

  methods: {
    ...mapActions({
      banUser: 'users/banUser',
    }),
    async save() {
      if (this.requestInProgress) return

      await this.banUser({
        userId: this.userId,
        reason: this.reason,
        comment: this.comment,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was banned',
              icon: 'UserXIcon',
              variant: 'info',
            },
          })
          this.$emit('ban')
          this.$emit('close')
        })
        .catch(this.checkErrors)
    },

  },
}
</script>

<style scoped lang="scss"></style>
