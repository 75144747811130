var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_vm._t("prependFilters"),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"mb-1 position-absolute loader"},[(_vm.loading)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_vm._e()],1),_c('vue-good-table',{key:_vm.count + _vm.loading + _vm.limit,staticClass:"overflow-scroll hide-scroll",attrs:{"style-class":"vgt-table striped","columns":_vm.formatedColumns,"rows":_vm.rows,"rtl":_vm.direction,"pagination-options":{
        enabled: _vm.isPagination,
        perPage: _vm.count,
      },"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
      if ( ref === void 0 ) ref = {};
      var column = ref.column;
      var row = ref.row;
      var formattedRow = ref.formattedRow;
return [_vm._t("default",function(){return [_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]},{"column":column,"row":row,"formattedRow":formattedRow})]}},(_vm.isPagination)?{key:"pagination-bottom",fn:function(){return [_c('div',{staticClass:"d-flex  justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.rowsPerPageOptions},on:{"change":_vm.changePageLimit},model:{value:(_vm.selectPerPage),callback:function ($$v) {_vm.selectPerPage=$$v},expression:"selectPerPage"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" "+_vm._s(_vm.$t('rowsPerTable'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.page,"total-rows":_vm.count,"per-page":_vm.limit,"last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item","first-number":""},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388)})],1)])]},proxy:true}:null],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }