export default [
  {
    label: 'Created At',
    field: 'createdAt',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Input',
    field: 'inputData',
  },
  {
    label: 'Output',
    field: 'outputData',
  },
]
