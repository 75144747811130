export default [
  {
    label: 'Type',
    field: 'transactionType',
  },
  {
    label: 'Crypto Coin',
    field: 'cryptoCoin',
  },
  {
    label: 'Fee coin',
    field: 'feeCurrency',
  },
  {
    label: 'Fee percent',
    field: 'feePercent',
  },
  {
    label: 'Minimal fee amount',
    field: 'minFeeAmount',
  },
  {
    label: 'Minimal transaction amount',
    field: 'minTransactionAmount',
  },
  {
    label: 'Save coefficient',
    field: 'saveCoefficient',
  },
  {
    label: 'Referrer 1st lvl percent',
    field: 'referrerFeePercent',
  },
  {
    label: 'Referrer 2nd lvl percent',
    field: 'referrer2FeePercent',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
