export default [
  {
    label: 'kyc.applicationID',
    field: 'id',
  },
  {
    label: 'kyc.tgUsernameId',
    field: 'user',
  },
  {
    label: 'level',
    field: 'level',
  },
  {
    label: 'status',
    field: 'status',
  },
  {
    label: 'kyc.submittedAt',
    field: 'submittedAt',
  },
  {
    label: 'responsible',
    field: 'responsible',
  },
  {
    label: 'kyc.updateByUser',
    field: 'updatedByUser',
  },
  {
    label: 'actions',
    field: 'actions',
  },
]
