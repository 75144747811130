var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',[_c('validation-observer',{ref:"validator",attrs:{"mode":"eager"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fee percent","label-for":"feePercent"}},[_c('validation-provider',{attrs:{"name":"feePercent","rules":"required|isNumeric|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"feePercent","type":"text","maxlength":"20","value":_vm.form.feePercent,"state":errors.length ? false : null},on:{"input":function (val) { return _vm.onInput(val, 'feePercent', 4); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Minimal fee amount","label-for":"minFeeAmount"}},[_c('validation-provider',{attrs:{"name":"minFeeAmount","rules":"required|isNumeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minFeeAmount","type":"text","maxlength":"20","value":_vm.form.minFeeAmount,"state":errors.length ? false : null},on:{"input":function (val) { return _vm.onInput(val, 'minFeeAmount', _vm.coin.decimals); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Min transaction amount","label-for":"minTransactionAmount"}},[_c('validation-provider',{attrs:{"name":"minTransactionAmount","rules":"required|isNumeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minTransactionAmount","type":"text","maxlength":"20","value":_vm.form.minTransactionAmount,"state":errors.length ? false : null},on:{"input":function (val) { return _vm.onInput(val, 'minTransactionAmount', _vm.coin.decimals); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Save coefficient","label-for":"saveCoefficient"}},[_c('validation-provider',{attrs:{"name":"saveCoefficient","rules":"isNumeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"saveCoefficient","type":"text","maxlength":"20","value":_vm.form.saveCoefficient,"state":errors.length ? false : null},on:{"input":function (val) { return _vm.onInput(val, 'saveCoefficient', _vm.coin.decimals); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Referrer 1st lvl percent","label-for":"referrerFeePercent"}},[_c('validation-provider',{attrs:{"name":"referrerFeePercent","rules":"isNumeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"referrerFeePercent","type":"text","maxlength":"20","value":_vm.form.referrerFeePercent,"state":errors.length ? false : null},on:{"input":function (val) { return _vm.onInput(val, 'referrerFeePercent', _vm.coin.decimals); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Referrer 2nd lvl percent","label-for":"referrer2FeePercent"}},[_c('validation-provider',{attrs:{"name":"referrer2FeePercent","rules":"isNumeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"referrer2FeePercent","type":"text","maxlength":"20","value":_vm.form.referrer2FeePercent,"state":errors.length ? false : null},on:{"input":function (val) { return _vm.onInput(val, 'referrer2FeePercent', _vm.coin.decimals); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Update ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }