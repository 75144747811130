export default [
  {
    label: 'Icon',
    field: 'icon',
  },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Symbol',
    field: 'symbol',
  },
  {
    label: 'Balance',
    field: 'balance',
  },
]
