import store from '@/store'

export const getInitialData = async (to, from, next) => {
  const listOwnerPermissions = JSON.parse(localStorage.getItem('permissions'))
  const storeListOwnerPermissions = store.getters['users/listOwnerPermissions']
  const userPermissions = listOwnerPermissions || storeListOwnerPermissions

  const hasInvitesPermission = userPermissions?.includes('user-invites-page')
  const isInvitesCounter = store.getters['userInvites/pendingCounter']
  if (isInvitesCounter === null && hasInvitesPermission) {
    await store.dispatch('userInvites/fetchPendingInvites').catch(console.log)
  }

  const hasSwapPermission = userPermissions?.includes('wallets-page')
  const isSwapFailCounter = store.getters['transactions/failedCounter']
  if (isSwapFailCounter === null && hasSwapPermission) {
    await store.dispatch('transactions/fetchFailedSwapTransactions').catch(console.log)
  }

  // const hasBuySellPermission = userPermissions?.includes('buy-sell-page')
  // const isBuySellFailedCounter = store.getters['pgTransactions/buySellFailedCounter']
  // if (isBuySellFailedCounter === null && hasBuySellPermission) {
  //   await store.dispatch('pgTransactions/fetchFailedBuySell').catch(console.log)
  // }

  next()
}
