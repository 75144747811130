var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"provider"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","input-id":"category","options":_vm.category,"reduce":function (option) { return option.id; },"placeholder":"Set category"},on:{"input":_vm.categoryChange},model:{value:(_vm.form.categoryId),callback:function ($$v) {_vm.$set(_vm.form, "categoryId", $$v)},expression:"form.categoryId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Network","label-for":"network"}},[_c('validation-provider',{attrs:{"name":"network","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.networks,"reduce":function (option) { return option.network; },"placeholder":"set network"},model:{value:(_vm.form.network),callback:function ($$v) {_vm.$set(_vm.form, "network", $$v)},expression:"form.network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Description EN","label-for":"descriptionEn"}},[_c('validation-provider',{attrs:{"name":"descriptionEn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Description EN"},model:{value:(_vm.form.description.en),callback:function ($$v) {_vm.$set(_vm.form.description, "en", _vm._n($$v))},expression:"form.description.en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Description RU","label-for":"descriptionRu"}},[_c('validation-provider',{attrs:{"name":"descriptionRu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Description RU"},model:{value:(_vm.form.description.ru),callback:function ($$v) {_vm.$set(_vm.form.description, "ru", _vm._n($$v))},expression:"form.description.ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"App Link","label-for":"appLink"}},[_c('validation-provider',{attrs:{"name":"appLink"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"App Link"},model:{value:(_vm.form.appLink),callback:function ($$v) {_vm.$set(_vm.form, "appLink", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.appLink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"TMA Link","label-for":"tmaLink"}},[_c('validation-provider',{attrs:{"name":"tmaLink"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"TMA Link"},model:{value:(_vm.form.tmaLink),callback:function ($$v) {_vm.$set(_vm.form, "tmaLink", _vm._n($$v))},expression:"form.tmaLink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Group Link","label-for":"groupLink"}},[_c('validation-provider',{attrs:{"name":"groupLink"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Group Link"},model:{value:(_vm.form.groupLink),callback:function ($$v) {_vm.$set(_vm.form, "groupLink", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.groupLink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v(" Active ")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"img url","label-for":"imgUrl"}},[_c('validation-provider',{attrs:{"name":"imgUrl"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Images url"},model:{value:(_vm.form.imgUrl),callback:function ($$v) {_vm.$set(_vm.form, "imgUrl", _vm._n($$v))},expression:"form.imgUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }