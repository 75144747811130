import axios from '@/plugins/axios'

export async function fetchUserGems({ userId }) {
  const response = await axios.get(`/users/${userId}/gems`)
  return response.data.items || []
}

export async function fetchUserVouchers({ userId }) {
  const response = await axios.get(`/users/${userId}/vouchers`)
  return response.data.items || []
}

export async function fetchUserMaterials({ userId }) {
  const response = await axios.get(`/users/${userId}/materials`)
  return response.data.items || []
}
