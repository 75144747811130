export const gemsFields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Name' },
  { key: 'rarity', label: 'Rarity' },
  { key: 'count', label: 'Count' },
  { key: 'status', label: 'Status' },
  // may be helful later
  // { key: 'condition', label: 'Condition' },
]
export const voucherFields = [
  { key: 'id', label: 'ID' },
  { key: 'symbol', label: 'Symbol' },
  { key: 'amount', label: 'Amount' },
  { key: 'network', label: 'Network' },
  { key: 'status', label: 'Status' },
]

export const materialFields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Name' },
  { key: 'quantity', label: 'Quantity' },
]

// Mocks
export const materials = [
  { id: 1, name: 'Скины ракеты HOLD', quantity: 200 },
  { id: 2, name: 'Кусочки Рубина', quantity: 300 },
]

// export const gems = [
//   {
//     id: 1,
//     name: 'quartz',
//     rarity: 'c',
//     count: '7',
//     status: 'available',
//     condition: null,
//   },
//   {
//     id: 6,
//     name: 'opal',
//     rarity: 'r',
//     count: '0',
//     status: 'unavailable',
//     condition: {
//       key: 'completed-wallet-task-1',
//       type: 'complete-task',
//       count: 1,
//       subtype: 'wallet',
//     },
//   },
// ]

// export const vouchers = [
//   {
//     id: 2,
//     symbol: 'USDT',
//     amount: 0.002,
//     network: 'TON',
//     status: 'redeemed',
//   },
//   {
//     id: 8,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'redeemed',
//   },
//   {
//     id: 63,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'available',
//   },
//   {
//     id: 83,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'available',
//   },
//   {
//     id: 84,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'available',
//   },
//   {
//     id: 85,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'available',
//   },
//   {
//     id: 86,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'available',
//   },
//   {
//     id: 88,
//     symbol: 'TON',
//     amount: 0.001,
//     network: 'TON',
//     status: 'available',
//   },
// ]
