<template>
  <div>
    <AppTable
      :rows="items.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: items.limit,
        count: items.count,
        page: items.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <AssetFilter v-model="selectCoin" label="Crypto" placeholder="Select crypto" @change="changeCoin" />
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search by username</label>
              <b-form-input v-model="searchUser" placeholder="Search by username" type="text" />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search by address</label>
              <b-form-input v-model="searchAddress" placeholder="Search by address" type="text" />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow :row="row" :column="column" :formatted-row="formattedRow" />

        <span v-if="column.field === 'user'" class="flex-center-align">
          <b-button
            v-if="row.user.firstName || row.user.lastName"
            variant="link"
            @click="toUser(row.user.id)"
            target="_blank"
            class="text-left"
          >
            {{ `${row.user.firstName || ''} ${row.user.lastName || ''}` }}
          </b-button>
          <b-button v-else variant="link" @click="toUser(row.user.id)" target="_blank" class="text-left">
            {{ row.user.telegram_username || row.user.name }}
          </b-button>
        </span>

        <span v-else-if="column.field === 'wallet'" class="flex-center-align">
          {{ row.seed.name }}
        </span>

        <span v-else-if="column.field === 'asset'" class="flex-center-align">
          <img :src="row.coin.image" alt="coin" class="coin-img" />
          <span class="ml-1">{{ `${row.coin.name} / ${row.network.network}` }}</span>
        </span>

        <span v-else-if="column.field === 'amount'" class="flex-center-align">
          {{ `${$decimal(row.balance).toDecimalPlaces(row.coin.decimals)}` }}
        </span>

        <span v-else-if="column.field === 'checkUrl'" class="flex-center-align">
          <a :href="row.explorerLink" target="_blank" class="p-1 ">{{ formatUrl(row.address) }}</a>
        </span>

        <span v-else-if="column.field === 'dataCreated'" class="flex-center-align">
          {{ formatDate(row.createdAt) || '' }}
        </span>

        <span v-else-if="column.field === 'amlRiskLevel'" class="flex-center-align">
          <b-badge :variant="getRiskLevelConfig(row).color" class="text-white">
            {{ getRiskLevelConfig(row).text }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'action'" class="flex-center-align">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-auto"
            type="button"
            variant="primary"
            @click="toTransaction(row)"
          >
            <span class="text-white" v-text="'Transactions'" />
          </b-button>
        </span>

        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import formatDate from '@/mixins/formatDate'
import transactions from '@/mixins/transactions'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'
import AssetFilter from '@/components/containers/AssetFilter.vue'

export default {
  name: 'WalletDeFi',
  components: {
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AppTable,
    TableRow,
    AssetFilter,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, formatDate, transactions],

  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      searchAddress: '',
      searchUser: '',
      selectCoin: '',
    }
  },

  computed: {
    ...mapGetters({
      items: 'wallets/walletsDeFi',
    }),
    direction() {
      return store.state.appConfig.isRTL
    },
    
    row() {
      return this.items.value
    }
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchListWalletsDeFi: 'wallets/fetchListWalletsDeFi',
    }),

    createQueryConfig(config) {
      return {
        page: this.items.page,
        limit: this.items.limit,

        walletAddress: this.searchAddress || undefined,
        username: this.searchUser || undefined,
        coinId: this.selectCoin?.id || undefined,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      this.fetchWallets(query)
    },

    search() {
      const query = this.createQueryConfig({
        walletAddress: this.searchAddress,
        username: this.searchUser,
        page: 1,
      })
      return this.fetchWallets(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchWallets(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchWallets(query)
    },
    changeCoin(coin) {
      const query = this.createQueryConfig({ coinId: coin?.id || null })
      return this.fetchWallets(query)
    },

    fetchWallets(query) {
      return this.waitRequest(() => this.fetchListWalletsDeFi(query).catch(this.checkErrors))
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.coin-img {
  width: 30px;
  border-radius: 50%;
}
</style>