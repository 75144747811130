<template>
  <b-row class="match-height">
    <b-col md="12">
      <b-card title="Identity Verification Statistics">
        <b-card-body>
          <b-row>
            <b-col v-for="(item, key) in dashBoard" :key="key" md="3">
              <b-row>
                <b-avatar :variant="`light-${item.color}`" class="mr-1" size="45">
                  <feather-icon :icon="item.icon" size="21" />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ $t(item.title) }}
                  </h2>
                  <span>{{ $t(item.description) }}</span>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <h1>{{ user }}</h1>
    </b-col>
  </b-row>
</template>
<script>
import { BAvatar, BCard, BCardBody, BCol, BRow } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'KYC',
  components: {
    BAvatar,
    BCard,
    BCardBody,
    BCol,
    BRow,
  },
  data() {
    return {
      dashboardInfo: {
        total: 0,
        verified: 0,
        inProgress: 0,
        rejected: 0,
      },
      color: 'primary',
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    dashBoard() {
      return [
        {
          color: 'primary',
          icon: 'UserIcon',
          title: this.$t(this.dashboardInfo.total),
          description: this.$t('kyc.dashboard.verificationApplications'),
        },
        {
          color: 'success',
          icon: 'CheckCircleIcon',
          title: this.$t(this.dashboardInfo.verified),
          description: this.$t('kyc.dashboard.verifiedApplications'),
        },
        {
          color: 'warning',
          icon: 'ClockIcon',
          title: this.$t(this.dashboardInfo.inProgress),
          description: this.$t('kyc.dashboard.verificationInProgress'),
        },
        {
          color: 'danger',
          icon: 'XCircleIcon',
          title: this.$t(this.dashboardInfo.rejected),
          description: this.$t('kyc.dashboard.rejectedApplications'),
        },
      ]
    },
  },
  mounted() {
    this.getDashboardInfo()
  },
  methods: {
    async getDashboardInfo() {
      const { data } = await this.$store.dispatch('kyc/fetchDashboardInfo')
      this.dashboardInfo = data
    },
  },
}
</script>
