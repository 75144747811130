import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '@/router/guards/AuthGuard'
import { getInitialData } from '@/router/getInitialData'

import usersRouterConfig from '@/views/apps/users/config/usersRouterConfig'
import usersInvitesRouterConfig from '@/views/apps/users-invites/config/usersInvitesRouterConfig'
import rolesRouterConfig from '@/views/apps/roles/config/rolesRouterConfig'
import createAdminRouterConfig from '@/views/apps/create-admin/config/createAdminRouterConfig'
import swapRouterConfig from '@/views/apps/swap/config/swapRouterConfig'
import buySellRouterConfig from '@/views/apps/buy-sell/config/buySellRouterConfig'
import currencyCryptoRouterConfig from '@/views/apps/currency-crypto/config/currencyCryptoRouterConfig'
import currencyFiatRouterConfig from '@/views/apps/currency-fiat/config/currencyFiatRouterConfig'
import p2pDealsRouterConfig from '@/views/apps/p2p-deals/config/p2pDealsRouterConfig'
import p2pOrdersRouterConfig from '@/views/apps/p2p-orders/config/p2pOrdersRouterConfig'
import coldWalletRouterConfig from '@/views/apps/cold-wallet/config/coldWalletRouterConfig'
import paymentMethodsRouterConfig from '@/views/apps/payment-methods/config/paymentMethodsRouterConfig'
import holdSettingsRouterConfig from '@/views/apps/hold-settings/config/holdSettingsRouterConfig'
import holdUsersRouterConfig from '@/views/apps/hold-users/config/holdUsersRouterConfig'
import holdLeaderboardWinnersRouterConfig from '@/views/apps/hold-leaderboard-winners/config/holdLeaderboardWinnersRouterConfig'
import holdAdditionalTasksRouterConfig from '@/views/apps/hold-additional-tasks/config/holdAdditionalTasksRouterConfig'
import transactionWalletRouterConfig from '@/views/apps/wallet-transactions/config/transactionWalletRouterConfig'
import analyticsRouterConfig from '@/views/apps/analytics-dashboard/config/analyticsRouterConfig'
import walletCeFiRouterConfig from '@/views/apps/wallet-cefi/config/walletCefiRouterConfig'
import walletDeFiRouterConfig from '@/views/apps/wallet-defi/config/walletDefiRouterConfig'
import teamRouterConfig from '@/views/apps/team/config/teamRouterConfig'
import faqRouterConfig from '@/views/apps/faq/config/faqRouterConfig'
import newsletterRouterConfig from '@/views/apps/newsletter/config/newsletterRouterConfig'
import journeyRouterConfig from '@/views/apps/journey/config/journeyRouterConfig'
import feeRouterConfig from '@/views/apps/fee/config/feeRouterConfig'
import buySellWalletRouterConfig from '@/views/apps/cold-wallet-buy-sell/config/buySellWalletRouterConfig'
import thresholdRouterConfig from '@/views/apps/threshold/config/thresholdRouterConfig'
import LimitsKycWalletRouterConfig from '@/views/apps/wallets-limits-kyc/config/limitsKycWalletRouterConfig'
import kycRouterConfig from '@/views/apps/kyc/config/kycRouterConfig.js'
import bannedUsersRouterConfig from '@/views/apps/banned-users/config/bannedUsersRouterConfig'
import amlRouterConfig from '@/views/apps/aml/config/amlRouterConfig'
import dappsSettingsRouterConfig from '@/views/apps/dapps/config/dappsSettingsRouterConfig'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...analyticsRouterConfig,

    ...usersRouterConfig,
    ...usersInvitesRouterConfig,

    ...currencyCryptoRouterConfig,
    ...currencyFiatRouterConfig,

    ...walletCeFiRouterConfig,
    ...walletDeFiRouterConfig,
    ...transactionWalletRouterConfig,

    ...swapRouterConfig,
    ...buySellRouterConfig,
    ...thresholdRouterConfig,
    ...feeRouterConfig,
    ...p2pDealsRouterConfig,
    ...p2pOrdersRouterConfig,

    ...coldWalletRouterConfig,
    ...buySellWalletRouterConfig,
    ...paymentMethodsRouterConfig,
    ...holdSettingsRouterConfig,
    ...holdUsersRouterConfig,
    ...holdLeaderboardWinnersRouterConfig,
    ...holdAdditionalTasksRouterConfig,

    ...rolesRouterConfig,
    ...createAdminRouterConfig,

    ...teamRouterConfig,
    ...faqRouterConfig,
    ...newsletterRouterConfig,
    ...journeyRouterConfig,
    ...LimitsKycWalletRouterConfig,

    ...kycRouterConfig,
    ...amlRouterConfig,
    ...bannedUsersRouterConfig,

    ...dappsSettingsRouterConfig,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        requiredAuth: false,
        layout: 'full',
      },
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/pages/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(authGuard)
router.beforeEach(getInitialData)
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
