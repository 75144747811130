var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rows)?_c('AppTable',{attrs:{"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
    limit: _vm.logItems.limit,
    count: _vm.logItems.count,
    page: _vm.logItems.page
  },"rows":_vm.rows},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"prependFilters",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"datepicker-placeholder"}},[_vm._v("Status")]),_c('b-form-select',{attrs:{"options":_vm.selectExecutorTypeOptions},on:{"change":_vm.changeExecutorType},model:{value:(_vm.selectExecutorType),callback:function ($$v) {_vm.selectExecutorType=$$v},expression:"selectExecutorType"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"datepicker-placeholder"}},[_vm._v("Start date")]),_c('b-form-datepicker',{attrs:{"close-button":"","local":"en","placeholder":"Choose a min date","reset-button":"","today-button":""},on:{"input":_vm.search},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"datepicker-placeholder"}},[_vm._v("End date")]),_c('b-form-datepicker',{attrs:{"close-button":"","local":"en","placeholder":"Choose a max date","reset-button":"","today-button":""},on:{"input":_vm.search},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('b-col',{staticClass:"mb-1 mt-auto pb-1",attrs:{"md":"1","xl":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-auto",attrs:{"disabled":_vm.requestInProgress,"type":"button","variant":"primary"},on:{"click":_vm.search}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"18"}})],1)],1)],1)]},proxy:true},{key:"default",fn:function(ref){
  var column = ref.column;
  var row = ref.row;
  var formattedRow = ref.formattedRow;
return [(column.field === 'type')?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Executor: "),_c('b',[_vm._v(_vm._s(row.executorType))])]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Type: "),_c('b',[_vm._v(_vm._s(row.type))])]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Sub type: "),_c('b',[_vm._v(_vm._s(row.subType))])])]):(['inputData', 'outputData'].includes(column.field))?_c('span',{staticClass:"code"},[_vm._v(_vm._s(row[column.field]))]):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}],null,false,27341997)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }