var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{attrs:{"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.amlFailedItems.limit,
      count: _vm.amlFailedItems.count,
      page: _vm.amlFailedItems.page,
    },"rows":_vm.rows},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
return [(column.field === 'feeTransaction')?_c('span',{staticClass:"flex-center-align"},[_c('span',{staticClass:"p-1 font-16 text-left text-primary cursor-pointer",on:{"click":function($event){return _vm.doCopy(row.feeTransaction.hash)}}},[_vm._v(" "+_vm._s(row.feeTransaction.text)+" ")])]):(column.field === 'refundTransaction')?_c('span',{staticClass:"flex-center-align"},[_c('span',{staticClass:"p-1 font-16 text-left text-primary cursor-pointer",on:{"click":function($event){return _vm.doCopy(row.refundTransaction.hash)}}},[_vm._v(" "+_vm._s(row.refundTransaction.text)+" ")])]):(column.field === 'actions')?_c('span',{staticClass:"d-flex flex-row"},[_c('b-button',{staticClass:"text-left",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.requeue(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Requeue'))+" ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }