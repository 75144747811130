export default [
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Category',
    field: 'category',
  },
  {
    label: 'Network',
    field: 'network',
  },
  {
    label: 'App Link',
    field: 'appLink',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Counter',
    field: 'clickCount',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
