export default [
  // transaction-monitor
  {
    path: '/dapps-settings',
    name: 'dapps-settings',
    component: () => import('@/views/apps/dapps/DappsSettings.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'DApps Settings',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },

  {
    path: '/dapps-settings/create',
    name: 'dapps-settings-create',
    component: () => import('@/views/apps/dapps/DappsCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      breadcrumb: [
        {
          text: 'DApps',
          active: true,
          to: '/dapps-settings',
        },
        {
          text: 'Create',
        },
      ],
    },
  },

  {
    path: '/dapps-settings/edit/:id',
    name: 'dapps-settings-edit',
    component: () => import('@/views/apps/dapps/DappsEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      breadcrumb: [
        {
          text: 'DApps',
          active: true,
          to: '/dapps-settings',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },

  {
    path: '/dapps-revenue-wallets',
    name: 'dapps-revenue-wallets',
    component: () => import('@/views/apps/dapps/DappsRevenue.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'DApps revenue wallets',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
]
