export default [
  {
    label: 'User',
    field: 'user',
  },
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Asset',
    field: 'asset',
  },
  {
    label: 'Amount',
    field: 'amount',
  },
  {
    label: 'Fee',
    field: 'fee',
  },
  {
    label: 'Destination',
    field: 'toAddress',
  },
  {
    label: 'TxId',
    field: 'checkUrl',
  },
  {
    label: 'Risk Level',
    field: 'amlRiskLevel',
  },
  {
    label: 'Status',
    field: 'status',
  },
]
