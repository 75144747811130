export const columnsCustom = [
  {
    label: 'Account Id',
    field: 'id',
    sortable: true,
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'TG username',
    field: 'telegram_username',
  },
  {
    label: 'TG id',
    field: 'telegram_id',
  },
  {
    label: 'KYC level',
    field: 'kycLevel',
    sortable: true,
  },
  {
    label: 'KYC status',
    field: 'kycStatus',
    sortable: true,
  },
  {
    label: 'Withdrawal',
    field: 'withdrawalStatus',
    sortable: true,
  },
]
export const columns = [
  {
    label: 'Account Id',
    field: 'id',
    sortable: true,
  },
  {
    label: 'Trust Credit',
    field: 'trustCredit',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'TG username',
    field: 'telegram_username',
  },
  {
    label: 'Registration date',
    field: 'createdAt',
  },
  {
    label: 'Last login time',
    field: 'lastActivity',
  },
  {
    label: 'User status (isApproved)',
    field: 'status',
    sortable: true,
  },
  {
    label: 'KYC level',
    field: 'kycLevel',
    sortable: true,
  },
  {
    label: 'KYC status',
    field: 'kycStatus',
    sortable: true,
  },
  {
    label: 'IP address',
    field: 'ipAddress',
    sortable: true,
  },
  {
    label: 'Country by Ip on login',
    field: 'countryByIp',
    sortable: true,
  },
  {
    label: 'Residence Country',
    field: 'residenceCountry',
    sortable: true,
  },
  {
    label: 'Is CeFi Enabled',
    field: 'isCeFiEnabled',
  },
  {
    label: 'Has Viewer Role',
    field: 'hasViewerRole',
  },
  {
    label: 'Action',
    field: 'action',
    sortable: true,
  },
]

export const userTransactions = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Asset',
    field: 'asset',
  },
  {
    label: 'Amount',
    field: 'amount',
  },
  {
    label: 'Fee',
    field: 'fee',
  },
  {
    label: 'Destination',
    field: 'toAddress',
  },
  {
    label: 'TxId',
    field: 'checkUrl',
  },
  {
    label: 'Status',
    field: 'status',
  },
]
