export default [
  {
    label: 'aml.tableHeader.tguser',
    field: 'tguser',
  },
  {
    label: 'aml.tableHeader.asset',
    field: 'asset',
  },
  {
    label: 'aml.tableHeader.amount',
    field: 'amount',
  },
  {
    label: 'aml.tableHeader.riskLevel',
    field: 'riskLevel',
  },
  {
    label: 'aml.tableHeader.status',
    field: 'status',
  },
  {
    label: 'aml.tableHeader.createdAt',
    field: 'createdAt',
  },
  {
    label: 'aml.tableHeader.admin',
    field: 'admin',
  },
  {
    label: 'aml.tableHeader.actions',
    field: 'actions',
  },
]

export const failed = [
  {
    label: 'aml.tableHeader.alertId',
    field: 'alertId',
  },
  {
    label: 'aml.tableHeader.failureDate',
    field: 'failureDate',
  },
  {
    label: 'aml.tableHeader.failureReason',
    field: 'failureReason',
  },
  {
    label: 'aml.tableHeader.feeTransaction',
    field: 'feeTransaction',
  },
  {
    label: 'aml.tableHeader.refundTransaction',
    field: 'refundTransaction',
  },
  {
    label: 'aml.tableHeader.requiredFee',
    field: 'requiredFee',
  },
  {
    label: 'aml.tableHeader.sentFee',
    field: 'sentFee',
  },
  {
    label: 'aml.tableHeader.verificationId',
    field: 'verificationId',
  },
  {
    label: 'aml.tableHeader.actions',
    field: 'actions',
  },
]
