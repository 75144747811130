import axios from '@/plugins/axios'

const state = () => ({
  dapps: [],
  item: {},
  category: [],
  networks: [],
  revenue: [],
})

const actions = {
  fetchDapps({ commit }) {
    return axios({ method: 'GET', url: '/apps' }).then(response => {
      commit('setDapps', response.data)

      return response
    })
  },
  fetchItem({ commit }, id) {
    return axios({ method: 'GET', url: `/apps/${id}` }).then(response => {
      commit('setItem', response.data)

      return response
    })
  },
  updateItem(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/apps/${id}`, data: form })
  },
  createItem(store, { form }) {
    return axios({ method: 'POST', url: '/apps', data: form })
  },
  deleteItem(store, id) {
    return axios({ method: 'DELETE', url: `/apps/${id}` })
  },
  fetchCategory({ commit }) {
    return axios({ method: 'GET', url: '/apps/categories' }).then(response => {
      commit('setCategory', response.data)

      return response
    })
  },
  fetchNetworks({ commit }) {
    return axios({ method: 'GET', url: '/apps/networks' }).then(response => {
      commit('setNetworks', response.data)

      return response
    })
  },
  fetchRevenue({ commit }) {
    return axios({ method: 'GET', url: '/apps/revenue-wallets' }).then(response => {
      commit('setRevenue', response.data)

      return response
    })
  },
}

const mutations = {
  setDapps(state, payload) {
    state.dapps = payload
  },
  setItem(state, payload) {
    state.item = payload
  },
  setCategory(state, payload) {
    state.category = payload
  },
  setNetworks(state, payload) {
    state.networks = payload
  },
  setRevenue(state, payload) {
    state.revenue = payload
  },
}

const getters = {
  dapps: state => state.dapps,
  item: state => state.item,
  category: state => state.category,
  networks: state => [
    { name: 'All', network: 'all' },
    ...(state.networks?.map(network => ({
      name: network.coin.name,
      ...network,
    })) || []),
  ],
  revenue: state => state.revenue,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
