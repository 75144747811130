var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.mode},[_c('AppTable',{attrs:{"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.bannedUsers.limit,
      count: _vm.bannedUsers.count,
      page: _vm.bannedUsers.page,
    },"rows":_vm.bannedUsers.value},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"prependFilters",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{staticClass:"mr-1"},[_vm._v("Telegram ID")]),_c('b-form-input',{attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.telegramID),callback:function ($$v) {_vm.telegramID=$$v},expression:"telegramID"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{staticClass:"mr-1"},[_vm._v("Telegram Username")]),_c('b-form-input',{attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('b-col',{staticClass:"mb-1 mt-auto pb-1",attrs:{"md":"1","xl":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-auto",attrs:{"disabled":_vm.requestInProgress,"type":"button","variant":"primary"},on:{"click":_vm.search}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"18"}})],1)],1)],1)]},proxy:true},{key:"default",fn:function(ref){
    if ( ref === void 0 ) ref = {};
    var column = ref.column;
    var row = ref.row;
    var formattedRow = ref.formattedRow;
return [_c('TableRow',{attrs:{"column":column,"formatted-row":formattedRow,"row":row}}),(column.field === 'telegram_username')?_c('span',[_c('a',{attrs:{"href":("https://t.me/" + (row.telegramUsername)),"target":"_blank"}},[_vm._v(" "+_vm._s(row.telegramUsername)+" ")])]):(column.field === 'telegram_id')?_c('span',[_vm._v(" "+_vm._s(row.telegramId)+" ")]):(column.field === 'ban_date')?_c('span',[_vm._v(" "+_vm._s(new Date(row.bannedAt).toLocaleDateString())+" ")]):(column.field === 'ban_reason')?_c('span',[_vm._v(" "+_vm._s(row.reason === 'other' ? row.comment : _vm.reasons[row.reason])+" ")]):(column.field === 'action')?_c('span',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-auto text-white",attrs:{"variant":row.status ? 'danger' : 'success',"type":"button"},on:{"click":function($event){return _vm.handleUnban(row)}}},[_vm._v(" Unban ")])],1):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }