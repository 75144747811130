import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  amlItems: {
    page: 1,
    limit: 30,
    items: [],
    count: 0,
  },
  amlFailedItems: {
    page: 1,
    limit: 30,
    items: [],
    count: 0,
  },
})

const getters = {
  amlItems: state => state.amlItems,
  amlFailedItems: state => state.amlFailedItems,
}

const actions = {
  fetchAmlAlerts({ commit }, requestQuery) {
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/aml/alerts', params }).then(response => {
      commit('setNewItems', { key: 'amlItems', payload: response.data, params })

      return response
    })
  },
  fetchAmlAlertById(store, { id }) {
    return axios({ method: 'GET', url: `/aml/alerts/${id}` })
  },
  fetchAmlFailedAlerts({ commit }) {
    return axios({ method: 'GET', url: '/aml/refunds/failed' }).then(response => {
      commit('setNewItems', { key: 'amlFailedItems', payload: response.data  })

      return response
    })
  },
  setAmlFailed({ commit }, { id }) {
    return axios({ method: 'POST', url: `refunds/failed/${id}/requeue` })
      .then(response => {
        commit('removeItem', { key: 'amlFailedItems', comparisonKey: 'id', comparisonValue: id })

        return response
      })
  },
  fetchAdmins() {
    return axios({ method: 'GET', url: '/aml/admins' })
  },
  setAdmin(store, { alertId, adminId }) {
    return axios({ method: 'POST', url: `/aml/alerts/${alertId}/assign`, data: { adminId } })
  },
  setAlertAprove(store, { id }) {
    return axios({ method: 'POST', url: `/aml/alerts/${id}/approve` })
  },
  setAlertDecline(store, { id, refund }) {
    return axios({ method: 'POST', url: `/aml/alerts/${id}/reject`, data: { refund } })
  },
}

const mutations = {
  setNewItems(state, { key = 'amlItems', payload, params }) {
    const { limit, page } = params

    state[key].items = payload.items || []
    state[key].count = payload.total
    state[key].limit = limit
    state[key].page = page
  },
  removeItem(state, { key = 'amlItems', comparisonKey, comparisonValue }) {
    state[key].items = [...state[key].items.filter(item => item[comparisonKey] !== comparisonValue)]
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
