<template>
  <div :key="mode">
    <AppTable
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: bannedUsers.limit,
        count: bannedUsers.count,
        page: bannedUsers.page,
      }"
      :rows="bannedUsers.value"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>

          <b-col
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">Telegram ID</label>
              <b-form-input
                v-model="telegramID"
                placeholder="Search"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">Telegram Username</label>
              <b-form-input
                v-model="username"
                placeholder="Search"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1 mt-auto pb-1"
            md="1"
            xl="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow
          :column="column"
          :formatted-row="formattedRow"
          :row="row"
        />

        <span v-if="column.field === 'telegram_username'">
          <a
            :href="`https://t.me/${row.telegramUsername}`"
            target="_blank"
          >
            {{ row.telegramUsername }}
          </a>
        </span>

        <span v-else-if="column.field === 'telegram_id'">
          {{ row.telegramId }}
        </span>
        <span v-else-if="column.field === 'ban_date'">
          {{ new Date(row.bannedAt).toLocaleDateString() }}
        </span>
        <span v-else-if="column.field === 'ban_reason'">
          {{ row.reason === 'other' ? row.comment : reasons[row.reason] }}
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="column.field === 'action'"
          class="d-flex"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="row.status ? 'danger' : 'success'"
            class="mt-auto text-white"
            type="button"
            @click="handleUnban(row)"
          >
            Unban
          </b-button>
        </span>

        <!-- default render cell -->
        <span
          v-else
          class="flex-center-align"
        >
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'

export default {
  components: {
    AppTable,
    TableRow,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columnsDefault: columns,
      telegramID: '',
      username: '',
      email: '',
      isModalOpened: false,
      currentUser: null,
      reasons: {
        bot: 'Bot',
        other: 'Other',
        multiaccount: 'Multi account',
        abuse: 'Abuse',
        aml: 'AML',
      },
    }
  },

  computed: {
    columns() {
      return this.mode === 'forbidden' ? this.columnsCustom : this.columnsDefault
    },
    ...mapGetters({
      bannedUsers: 'users/bannedUsers',
      listUsers: 'users/listUsers',
      totalUsers: 'users/totalUsers',
      pageUserPagination: 'users/pageUserPagination',
    }),

    mode() {
      const { name } = this.$route
      return name.includes('kyc') ? 'forbidden' : 'users'
    },

    direction() {
      return store.state.appConfig.isRTL
    },
  },
  watch: {
    mode() {
      this.telegramID = ''
      this.username = ''
      this.email = ''
      const query = this.createQueryConfig({ page: 1 })

      this.fetchUser(query)
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchBannedUsers: 'users/fetchBannedUsers',
      unbanUser: 'users/unbanUser',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.fetchUsers(query)
    },

    search() {
      const query = this.createQueryConfig({
        telegramId: this.telegramID,
        telegramUsername: this.username,
        email: this.email,
        page: 1,
      })

      return this.fetchUsers(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.fetchUsers(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.fetchUsers(query)
    },

    createQueryConfig(config) {
      return {
        page: this.bannedUsers.page,
        limit: this.bannedUsers.limit,
        mode: this.mode,
        telegramID: this.telegramID || undefined,
        username: this.username || undefined,
        email: this.email || undefined,

        ...config,
      }
    },

    fetchUsers(query) {
      return this.waitRequest(() => this.fetchBannedUsers(query).catch(this.checkErrorsAlert))
    },

    handleUnban(user) {
      if (this.requestInProgress) return

      this.$swal({
        title: 'Do you want to unban user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, unban '${user?.telegramUsername}'!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (!result.isConfirmed) return

        this.unban(user)
      })
    },

    unban(user) {
      this.waitRequest(() => this.unbanUser({ userId: user.id })).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'User was unbanned',
            icon: 'BellIcon',
          },
        })
        this.initState()
      }).catch(this.checkErrors)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
