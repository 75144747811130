export default [
  {
    path: '/aml/all',
    name: 'aml-all',
    component: () => import('@/views/apps/aml/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-list',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
  {
    path: '/aml/progress',
    name: 'aml-progress',
    component: () => import('@/views/apps/aml/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-list',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
  {
    path: '/aml/approved',
    name: 'aml-approved',
    component: () => import('@/views/apps/aml/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-list',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
  {
    path: '/aml/declined',
    name: 'aml-declined',
    component: () => import('@/views/apps/aml/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-list',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
  {
    path: '/aml/failed',
    name: 'aml-failed',
    component: () => import('@/views/apps/aml/failed.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-list',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
  {
    path: '/aml/new',
    name: 'aml-new',
    component: () => import('@/views/apps/aml/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-list',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
  {
    path: '/aml/:id',
    name: 'aml-id',
    component: () => import('@/views/apps/aml/_id/index.vue'),
    meta: {
      requiredAuth: true,
      permission: 'aml-alerts-view',
      pageTitle: 'AML',
      breadcrumb: [
        {
          text: 'AML',
        },
      ],
    },
  },
]
