const columns = [
  {
    label: 'TG username',
    field: 'telegram_username',
  },
  {
    label: 'TG id',
    field: 'telegram_id',
  },
  {
    label: 'Ban Date',
    field: 'ban_date',
  },
  {
    label: 'Ban Reason',
    field: 'ban_reason',
  },
  {
    label: 'Action',
    field: 'action',
  },
]

export default columns
