<template>
  <div>
    <AppTable
      v-if="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: amlItems.limit,
        count: amlItems.count,
        page: amlItems.page,
      }"
      :rows="rows"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col class="mb-1" md="6" xl="6">
            <AssetFilter v-model="selectCoin" label="Crypto" placeholder="Select crypto" @change="changeCoin" />
          </b-col>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('aml.status') }}</label>
              <b-form-select v-model="selectStatus" :options="statusOptions" @change="changeStatus" />
            </b-form-group>
          </b-col>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('aml.riskLevel') }}</label>
              <b-form-select v-model="selectLevel" :options="levelOptions" @change="changeLevel" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mb-1 mt-auto pb-1" md="3" xl="3">
            <div class="full-width">
              <label class="mr-1">{{ $t('aml.responsible') }}</label>
              <AMLAdminSelect @change="onAdminChange" />
            </div>
          </b-col>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">TG Username</label>
              <b-form-input v-model="telegramUsername" placeholder="TG Username" type="text" />
            </b-form-group>
          </b-col>
          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">TG Id</label>
              <b-form-input v-model="telegramId" placeholder="tg Id" type="text" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">{{ $t('aml.sortColumn') }}</label>
              <b-form-select
                v-model="sortColumn"
                :options="sortedColumnsOptions"
                @change="changeSort"
                placeholder=""
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">{{ $t('aml.sortTime') }}</label>
              <b-form-select
                v-model="sortOrder"
                :options="sortedOrderOptions"
                @change="changeSortOrder"
                placeholder=""
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">{{ $t('aml.assigned') }}</label>
              <b-form-checkbox
                class="mt-1"
                id="checkbox-1"
                v-model="assigned"
                name="checkbox-1"
                @change="changeAssigne"
              >
                <!--                Assigned-->
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col class="mb-1 mt-auto pb-1" md="1" xl="1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <div v-if="column.field === 'tguser'">
          <div v-if="row.username">
            {{ row.user.username }}
          </div>

          <b-badge v-if="row.user.telegramId" class="text-white" variant="secondary">
            {{ row.user.telegramId }}
          </b-badge>
        </div>

        <!--        updatedByUser-->
        <b-avatar
          v-else-if="column.field === 'updatedByUser'"
          v-show="!row.updatedByUser"
          size="20"
          variant="light-success"
        >
          <feather-icon icon="CheckCircleIcon" size="18" />
        </b-avatar>

        <span v-if="column.field === 'admin'" class="flex-center-align ">
          <feather-icon :icon="row.admin ? 'UserIcon' : 'InfoIcon'" size="18" />
          {{ row.admin ? row.admin.username : $t('aml.needAnOfficer') }}
        </span>

        <span v-else-if="column.field === 'status'" class="flex-center-align">
          <b-badge :variant="row.status.color" class="text-white">
            {{ row.status.text || '' }}
          </b-badge>
        </span>
        <span v-else-if="column.field === 'riskLevel'" class="flex-center-align">
          <b-badge :variant="row.riskLevel.color" class="text-white">
            {{ row.riskLevel.text || '' }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'actions'" class="d-flex flex-row">
          <b-button class="text-left" :href="`/aml/${row.id}`" target="_blank" variant="primary" >
            {{ $t('aml.view') }}
          </b-button>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'
import { formattedDate } from '@/tools/util'
import {
  amlRiskLevelTypes,
  amlRoutes,
  amlStatusColors,
  amlStatusTypes,
  amlStatusText,
  amlSortedOrderTypes,
  amlSortedColumnsTypes,
  amlRiskLevelColors,
  amlStatus,
} from '@/views/apps/aml/config/amlTypes'
import doCopy from '@/mixins/doCopy'
// import UserFilter from '@/components/containers/UserFilter.vue'
import AssetFilter from '@/components/containers/AssetFilter.vue'
import AMLAdminSelect from '@/views/apps/aml/components/AMLAdminSelect.vue'

export default {
  name: 'AmlAll',
  components: {
    AMLAdminSelect,
    AssetFilter,
    // UserFilter,
    BFormDatepicker,
    BAvatar,
    BBadge,
    AppTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, checkErrorsAlert, doCopy],
  data() {
    return {
      columnsAll: columns,
      amlStatusColors,
      amlRiskLevelColors,
      telegramUsername: '',
      telegramId: '',
      responsibleId: '',
      selectStatus: '',
      selectLevel: '',
      selectCoin: '',
      adminId: null,
      sortColumn: null,
      sortOrder: null,
      assigned: false,
      statusOptions: ['', ...this.formatOptions(amlStatusText)],
      levelOptions: ['', ...this.formatOptions(amlRiskLevelTypes)],
      sortedColumnsOptions: ['', ...this.formatOptions(amlSortedColumnsTypes)],
      sortedOrderOptions: ['', ...this.formatOptions(amlSortedOrderTypes)],
    }
  },
  computed: {
    ...mapGetters({
      amlItems: 'aml/amlItems',
    }),
    columns() {
      return this.columnsAll
      // return this.$route.name === 'aml-progress'
      //   ? this.columnsAll
      //   : this.columnsAll?.filter(column => column.field !== 'updatedByUser')
    },

    rows() {
      return (
        this.amlItems?.items?.map(item => {
          const user = {
            username: item.username || '',
            telegramId: item.telegramId || '',
          }
          const createdAt = this.formatDate(item.createdAt)
          const asset = `${item.coin} ${item.network}`

          const riskLevel = {
            text: item.riskLevel,
            color: this.amlRiskLevelColors?.[item.riskLevel],
          }

          const status = {
            text: item.status,
            color: this.amlStatusColors?.[item.status],
          }

          return {
            ...item,
            status,
            createdAt,
            asset,
            user,
            riskLevel,
          }
        }) || []
      )
    },
  },

  watch: {
    $route() {
      this.firstInit()
    },
  },

  mounted() {
    this.firstInit()
  },
  methods: {
    ...mapActions({
      fetchAdminUsers: 'adminUser/fetchAdminUsers',
      fetchTable: 'aml/fetchAmlAlerts',
    }),

    formatOptions(options) {
      return Object.entries(options).map(([key, value]) => ({ value: key, text: this.$t(value) })) || []
    },
    formattedDate,
    formatDate(date) {
      if (!date) return

      return date
        ? this.formattedDate(date, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
        : ''
    },
    firstInit() {
      this.getInitialConfig(this.$route.name)

      const query = this.createQueryConfig({ page: 1, status: this.selectStatus })
      this.getamlItems(query)
    },

    initState() {
      const query = this.createQueryConfig()

      this.getamlItems(query)
    },

    search() {
      const query = this.createQueryConfig({
        page: 1,
      })

      return this.getamlItems(query)
    },

    changeCoin(asset) {
      const query = this.createQueryConfig({ coinId: asset?.id || null })

      return this.getamlItems(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getamlItems(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getamlItems(query)
    },

    changeLevel(level) {
      const riskLevel = amlRiskLevelTypes[level]
      const query = this.createQueryConfig({ riskLevel })

      return this.getamlItems(query)
    },

    changeStatus(type) {
      this.selectStatus = type

      const query = this.createQueryConfig({ status: type })

      return this.getamlItems(query)
    },

    createQueryConfig(config = {}) {
      return {
        riskLevel: this.selectLevel || null,
        page: this.amlItems?.page || 1,
        limit: this.amlItems?.limit || 30,
        telegramUsername: this.telegramUsername || null,
        responsibleId: this.responsibleId || null,
        telegramId: this.telegramId || null,
        selectCoin: this.selectCoin?.id || null,
        status: this.selectStatus || null,
        adminId: this.adminId?.id || null,
        sortColumn: this.sortColumn || null,
        sortOrder: this.sortOrder || null,
        unassigned: this.assigned || null,

        ...config,
      }
    },

    getamlItems(query) {
      return this.waitRequest(() => this.fetchTable(query).catch(this.checkErrorsAlert))
    },

    getInitialConfig(route) {
      this.resetData()

      switch (route) {
        case amlRoutes.new:
          this.changeSort('createdAt')
          break
        case amlRoutes.progress:
          this.changeStatus(amlStatus.pending)
          break
        case amlRoutes.approved:
          this.changeStatus(amlStatus.approved)
          break
        case amlRoutes.declined:
          this.changeStatus(amlStatus.declined)
          break

        default:
          const query = this.createQueryConfig()
          this.getamlItems(query)
          break
      }
    },

    onAdminChange(adminId) {
      this.adminId = adminId

      const query = this.createQueryConfig({ adminId: this.adminId.id })
      return this.getamlItems(query)
    },

    changeSort(type) {
      this.sortColumn = type
      const query = this.createQueryConfig({ sortColumn: type })
      return this.getamlItems(query)
    },

    changeSortOrder(type) {
      this.sortOrder = type
      const query = this.createQueryConfig({ sortOrder: type })
      return this.getamlItems(query)
    },

    changeAssigne(value) {
      this.assigned = value
      const query = this.createQueryConfig({ unassigned: this.assigned })
      return this.getamlItems(query)
    },

    resetData() {
      this.telegramUsername = ''
      this.telegramId = ''
      this.responsibleId = ''
      this.selectStatus = ''
      this.selectLevel = ''
      this.selectCoin = ''
      this.adminId = null
      this.sortColumn = null
      this.sortOrder = null
      this.assigned = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-good-table.scss';
legend {
  font-size: 0.857rem !important;
  font-weight: 600;
}
</style>
