<template>
  <div>
    <div
      v-for="(items, index) in revenue"
      :key="index"
    >
      <b-card no-body>
        <h3 class="mt-2 ml-2 mr-2 mb-0">
          {{ index }}
        </h3>
        <AppTable
          :rows="items"
          :columns="columns"
          :is-loading="requestInProgress"
          :is-pagination="false"
        >
          <template #default="{ column, row, formattedRow }">
            <span v-if="column.field === 'icon'">
              <img
                :src="row.coin.image"
                alt="icon"
                class="currency-icon"
              >
            </span>
            <span v-else-if="column.field === 'name'">
              {{ row.coin.name }}
            </span>
            <span v-else-if="column.field === 'symbol'">
              {{ row.coin.symbol }}
            </span>

            <span
              v-else
              class="flex-center-align"
            >
              {{ formattedRow[column.field] }}
            </span>
          </template>
        </AppTable>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import { secondsToReadableFormat } from '@/tools/util'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import AppTable from '@/components/AppTable.vue'

import columns from './config/tableConfigRevenue'

export default {
  name: 'DappsSettings',
  components: {
    AppTable,
    BCard,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      revenue: 'dapps/revenue',
    }),
  },
  mounted() {
    this.initState()
  },

  methods: {
    secondsToReadableFormat,
    ...mapActions({
      fetchRevenue: 'dapps/fetchRevenue',
    }),

    async initState() {
      await this.fetchRevenue().catch(this.checkErrors)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.currency-icon {
  width: 2rem;
}
</style>
