<template>
  <b-card
    name="inventory"
    class="inventory"
  >
    <div class="inventory__header">
      <b-card-header
        class="inventory__title"
        title-tag="h3"
        @click="toggleInventory"
      >
        <span>Inventory</span>
        <span :class="['inventory__arrow', { 'inventory__arrow_rotated': isCollapsed }]" />
      </b-card-header>
      <b-button
        variant="primary"
        size="sm"
        @click="refreshInventory"
      >
        Refresh
      </b-button>
    </div>
    <b-collapse
      id="inventory-collapse"
      v-model="isCollapsed"
      toggle="#false"
    >
      <b-card-body class="p-0 mt-1">
        <b-tabs v-model="activeTab">
          <b-tab
            title="Gems"
            class="mt-2"
          >
            <InventoryGems :gems="gemsData" />
          </b-tab>
          <b-tab
            title="Vouchers"
            class="mt-2"
          >
            <InventoryVouchers :vouchers="vouchersData" />
          </b-tab>
          <b-tab
            title="Materials"
            class="mt-2"
          >
            <InventoryMaterials :materials="materialsData" />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BButton, BCard, BCardBody, BCardHeader, BCollapse,
} from 'bootstrap-vue'
import InventoryGems from './InventoryGems.vue'
import InventoryVouchers from './InventoryVouchers.vue'
import InventoryMaterials from './InventoryMaterials.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { fetchUserGems, fetchUserVouchers } from './api'
import { materials } from './inventoryData'

export default {
  name: 'UserInventory',
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCollapse,
    InventoryGems,
    InventoryVouchers,
    InventoryMaterials,
  },
  data() {
    return {
      userId: null,
      isCollapsed: false,
      activeTab: 0,

      vouchersData: [],
      gemsData: [],
      materialsData: [],
    }
  },
  async mounted() {
    this.userId = this.$route.params.id
    await this.refreshInventory()
  },
  methods: {
    async getTableData() {
      const { userId } = this
      const [vouchersData, gemsData, materialsData] = await Promise.all([
        fetchUserVouchers({ userId }),
        fetchUserGems({ userId }),
        // TODO add after linked features
        // fetchUserMaterials({ userId }),
      ])
      this.vouchersData = vouchersData
      this.gemsData = gemsData
      this.materialsData = materialsData || materials // Mock
    },
    toggleInventory() {
      this.isCollapsed = !this.isCollapsed
    },

    async refreshInventory() {
      try {
        await this.getTableData()

        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'default',
            title: 'Inventory data uploaded',
            variant: 'success',
          },
        })
      } catch {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Inventory data is not uploaded',
            icon: 'UserPlusIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.inventory {
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
}
.inventory__title {
  padding: 0;
  font-size: 2em;

  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;

  cursor: pointer;
}

.inventory__arrow {
  display: inline-block;
  transition: transform 0.3s;
  content: '';
  border: solid white;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(45deg);
}

.inventory__arrow_rotated {
  transform: rotate(225deg);
}
</style>
