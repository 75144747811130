<template>
  <div>
    <AppTable
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: amlFailedItems.limit,
        count: amlFailedItems.count,
        page: amlFailedItems.page,
      }"
      :rows="rows"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #default="{ column, row }">
        <span v-if="column.field === 'feeTransaction'" class="flex-center-align">
          <span class="p-1 font-16 text-left text-primary cursor-pointer" @click="doCopy(row.feeTransaction.hash)">
            {{ row.feeTransaction.text }}
          </span>
        </span>

        <span v-else-if="column.field === 'refundTransaction'" class="flex-center-align">
          <!-- <b-button variant="link" @click="doCopy(row.toAddress.raw)" class="p-1 font-16 text-left">
            {{ row.toAddress.text }}
          </b-button> -->
          <span class="p-1 font-16 text-left text-primary cursor-pointer" @click="doCopy(row.refundTransaction.hash)">
            {{ row.refundTransaction.text }}
          </span>
        </span>

        <span v-else-if="column.field === 'actions'" class="d-flex flex-row">
          <b-button class="text-left" variant="primary" @click="requeue(row)">
            {{ $t('Requeue') }}
          </b-button>
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import AppTable from '@/components/AppTable.vue'
import { failed as columns } from './config/tableConfig'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import doCopy from '@/mixins/doCopy'
import { formatLongString, formattedDate } from '@/tools/util'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AMLFailed',
  components: {
    ToastificationContent,
    AppTable,
    BButton,
  },
  mixins: [waitRequest, checkErrorsAlert, doCopy],
  computed: {
    ...mapGetters({
      amlFailedItems: 'aml/amlFailedItems',
    }),
    columns() {
      return columns
    },
    rows() {
      return (
        this.amlFailedItems.items?.map(item => {
          console.log('item', item)
          const failureDate = this.formatDate(item?.failureDate)
          const feeTransaction = {
            text: formatLongString(item?.feeTransaction?.hash || ''),
            hash: item?.feeTransaction?.hash || '',
          }
          const refundTransaction = {
            text: formatLongString(item?.refundTransaction.hash || ''),
            hash: item?.refundTransaction.hash || '',
          }
          const requiredFee = `${item.requiredFee.amount} ${item.requiredFee.symbol}`
          const sentFee = `${item.sentFee.amount} ${item.sentFee.symbol}`

          return {
            ...item,
            failureDate,
            feeTransaction,
            refundTransaction,
            requiredFee,
            sentFee,
          }
        }) || []
      )
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchAmlFailedAlerts: 'aml/fetchAmlFailedAlerts',
      setAmlFailed: 'aml/setAmlFailed',
    }),
    initState() {
      this.getItems()
    },
    getItems() {
      return this.waitRequest(() => this.fetchAmlFailedAlerts().catch(this.checkErrorsAlert))
    },
    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getamlItems(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getamlItems(query)
    },
    formattedDate,
    formatDate(date) {
      if (!date) return

      return date
        ? this.formattedDate(date, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
        : ''
    },
    requeue(row) {
      if (this.requestInProgress) return

      this.waitRequest(() => {
        return this.setAmlFailed({ id: row.alertId })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Approved',
                icon: 'UserPlusIcon',
                variant: 'success',
              },
            })
          })
          .catch(this.checkErrorsAlert)
      })
    },
  },
}
</script>
