<template>
  <b-table
    :items="materials"
    :fields="materialFields"
    bordered
  />
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { materialFields } from './inventoryData'

export default {
  name: 'InventoryMaterials',
  components: {
    BTable,
  },
  props: {
    materials: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    materialFields,
  }),
}
</script>

<style>
</style>
