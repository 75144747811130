export const amlRiskLevelTypes = {
  none: 'none',
  low: 'low',
  medium: 'medium',
  high: 'high',
  severe: 'severe',
}

export const amlRoutes = {
  all: 'aml-all',
  new: 'aml-new',
  progress: 'aml-progress',
  declined: 'aml-declined',
  approved: 'aml-approved',
  failed: 'aml-failed',
}

export const amlStatusText = {
  pending: 'pending',
  processing: 'in progress',
  rejected: 'declined',
  approved: 'approved',
}

export const amlStatus = {
  pending: 'pending',
  'in progress': 'processing',
  declined: 'rejected',
  approved: 'approved',
}

export const amlStatusTypes = {
  new: ['new'],
  progress: ['in_progress', 'pending', 'retry', 'unverified'],
  approved: ['approved'],
  declined: ['declined'],
}

export const amlStatusColors = {
  approved: 'success',
  declined: 'danger',
  rejected: 'danger',
  processing: 'info',
}
export const amlRiskLevelColors = {
  none: 'disabled',
  low: 'success',
  medium: 'warning',
  high: 'danger',
  severe: 'info',
}

export const amlSortedColumnsTypes = {
  createdAt: 'aml.created',
  amount: 'aml.amount',
}

export const amlSortedOrderTypes = {
  asc: 'aml.newest',
  desc: 'aml.oldest',
}
