<template>
  <div class="inputs full-width">
    <v-select
      v-model="selectAdmin"
      v-b-tooltip.focus.v-primary
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="username"
      :options="adminsOptionsList"
      placeholder="set responsible"
      title="Start writing to see options"
      variant="outline-primary"
      @input="adminChange"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'

export default {
  name: 'AMLAdminSelect',
  components: {
    VBTooltip,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [waitRequest, checkErrorsAlert],
  props: {
    initialAdmin: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectAdmin: null,
      adminsOptionsList: [],
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchAdminUsers: 'aml/fetchAdmins',
      setAdmin: 'aml/setAdmin',
    }),
    initState() {
      this.getAdmins()
    },

    getAdmins() {
      this.fetchAdminUsers()
        .then(response => {
          this.adminsOptionsList = response.data?.admins
          this.selectAdmin = this.initialAdmin || null

          return response
        })
        .catch(this.checkErrorsAlert)
    },

    adminChange(admin) {
      this.$emit('change', admin)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
