<template>
  <div class="cdd-sources">
    <validation-observer
      ref="validator"
      mode="eager"
    >
      <b-form
        class="cdd-sources__form d-flex flex-column"
        @submit.prevent
      >
        <b-form-group class="cdd-sources__form-group">
          <label class="mr-1">Cdd Source</label>
          <b-form-select
            v-model="form.id"
            :options="cddSources"
            value-field="id"
            text-field="label"
            class="flex-1"
          />
        </b-form-group>

        <b-form-group class="cdd-sources__form-group">
          <validation-provider
            #default="{ errors }"
            name="url"
            rules="required|url"
          >
            <label class="mr-1">URL</label>
            <b-form-input
              v-model="form.url"
              placeholder="CDD SOURCE"
              type="text"
              class="flex-1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="requestInProgress"
          class="cdd-sources__button h-100"
          @click="validationForm"
        >
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormInput, BFormSelect, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import validationError from '@/mixins/validationError'
import waitRequest from '@/mixins/waitRequest'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'

export default {
  name: 'KycCddSources',
  components: {
    BFormInput,
    BForm,
    BFormSelect,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [checkErrorsAlert, validationError, waitRequest],
  data() {
    return {
      form: {
        id: null,
        url: null,
      },
      cddSources: [],
    }
  },
  computed: {
    selectedCDDSource() {
      return this.cddSources.find(({ id }) => id === this.form.id)
    },
  },
  watch: {
    selectedCDDSource(value) {
      this.form.url = value?.url
    },
  },
  async mounted() {
    try {
      this.registerValidator(this.$refs.validator)
      const sources = await this.fetchCddSources()
      this.cddSources = sources.map(source => ({ ...source, label: this.$t(`kyc.cddSource.${source.name}`) }))
      this.form.id = this.cddSources[0]?.id
    } catch (error) {
      this.checkErrorsAlert(error)
    }
  },
  methods: {
    ...mapActions({
      fetchCddSources: 'kyc/fetchCddSources',
      updateCddSource: 'kyc/updateCddSource',
    }),
    async validationForm() {
      if (this.requestInProgress) return

      const isValid = await this.checkIsValidForm()
      if (!isValid) return

      this.waitRequest(() => this.updateCddSource({ id: this.form.id, data: { url: this.form.url } })
        .then(cddSource => {
          const index = this.cddSources.findIndex(({ id }) => id === cddSource.id)
          if (index < 0) return
          this.$set(this.cddSources, index, { ...this.cddSources[index], ...cddSource })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('kyc.cddSource.updated'),
              icon: 'PlusIcon',
              variant: 'success',
            },
          })
        })
        .catch(this.checkErrors))
    },
  },
}
</script>
<style scoped lang="scss">
.cdd-sources {
  .cdd-sources__form {
    gap: 8px;
  }

  .cdd-sources__form-group {
    flex: 1;
    max-width: 400px;
  }

  .cdd-sources__button {
    width: fit-content;
  }
}
</style>
