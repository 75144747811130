<template>

  <AppTable
    v-if="rows"
    :columns="columns"
    :is-loading="requestInProgress"
    :pagination="{
      limit: logItems.limit,
      count: logItems.count,
      page: logItems.page
    }"
    :rows="rows"
    @change-limit="changePageLimit"
    @change-page="changePage"
  >
    <template #prependFilters>
      <b-row>
        <b-col
          class="mb-1"
          md="3"
          xl="3"
        >
          <b-form-group>
            <label for="datepicker-placeholder">Status</label>
            <b-form-select
              v-model="selectExecutorType"
              :options="selectExecutorTypeOptions"
              @change="changeExecutorType"
            />
          </b-form-group>
        </b-col>

        <b-col
          class="mb-1"
          md="3"
          xl="3"
        >
          <b-form-group>
            <label for="datepicker-placeholder">Start date</label>
            <b-form-datepicker
              v-model="dateFrom"
              close-button
              local="en"
              placeholder="Choose a min date"
              reset-button
              today-button
              @input="search"
            />
          </b-form-group>
        </b-col>

        <b-col
          class="mb-1"
          md="3"
          xl="3"
        >
          <b-form-group>
            <label for="datepicker-placeholder">End date</label>
            <b-form-datepicker
              v-model="dateTo"
              close-button
              local="en"
              placeholder="Choose a max date"
              reset-button
              today-button
              @input="search"
            />
          </b-form-group>
        </b-col>

        <b-col
          class="mb-1 mt-auto pb-1"
          md="1"
          xl="1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="requestInProgress"
            class="mt-auto"
            type="button"
            variant="primary"
            @click="search"
          >
            <feather-icon
              icon="SearchIcon"
              size="18"
            />
          </b-button>
        </b-col>

        <!--          <b-col-->
        <!--            class="mb-1"-->
        <!--            md="3"-->
        <!--            xl="3"-->
        <!--          >-->
        <!--            <b-form-group>-->
        <!--              <label class="mr-1">ResponsibleId</label>-->
        <!--              <b-form-input-->
        <!--                v-model="responsibleId"-->
        <!--                placeholder="Responsible Id"-->
        <!--                type="text"-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->

      </b-row>
    </template>

    <template #default="{ column, row, formattedRow }">
      <div
        v-if="column.field === 'type'"
        class="d-flex flex-column"
      >
        <span class="text-nowrap">
          Executor: <b>{{ row.executorType }}</b>
        </span>
        <span class="text-nowrap">
          Type: <b>{{ row.type }}</b>
        </span>
        <span class="text-nowrap">
          Sub type: <b>{{ row.subType }}</b>
        </span>
      </div>
      <span
        v-else-if="['inputData', 'outputData'].includes(column.field)"
        class="code"
      >{{ row[column.field] }}</span>
      <span
        v-else
        class="flex-center-align"
      >
        {{ formattedRow[column.field] }}
      </span>
    </template>
  </AppTable>
</template>

<script>
import {
  BButton, BCol, BFormDatepicker, BFormGroup, BFormSelect, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import columns from '../config/logTableConfig'
import AppTable from '@/components/AppTable.vue'
import { formattedDate } from '@/tools/util'
import { kycExecutorTypes } from '@/views/apps/kyc/config/kycTypes'
import doCopy from '@/mixins/doCopy'

export default {
  name: 'KycLogs',
  components: {
    BFormDatepicker,
    AppTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, checkErrorsAlert, doCopy],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      executorType: '',
      dateTo: '',
      dateFrom: '',
      columns,
      selectExecutorType: '',
      selectExecutorTypeOptions: ['', ...Object.keys(kycExecutorTypes)],
      logItems: {
        items: [],
        page: 1,
        limit: 30,
        count: 0,
      },
    }
  },
  computed: {

    rows() {
      return this.logItems.items?.map(item => {
        const createdAt = item.createdAt ? this.formattedDate(item.createdAt, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }) : ''
        console.log('oi')
        return {
          ...item,
          createdAt,
        }
      })
    },
  },
  watch: {
    $route() {
      this.firstInit()
    },
  },
  mounted() {
    this.firstInit()
  },
  methods: {
    firstInit() {
      const query = this.createQueryConfig({ page: 1 })
      this.getLogItems(query)
    },

    formattedDate,

    ...mapActions({
      fetchTable: 'kyc/fetchLogs',
    }),

    initState() {
      const query = this.createQueryConfig()

      this.getLogItems(query)
    },

    search() {
      const query = this.createQueryConfig({
        page: 1,
      })

      return this.getLogItems(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getLogItems(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getLogItems(query)
    },

    changeExecutorType(level) {
      const executorType = kycExecutorTypes[level]

      const query = this.createQueryConfig({ executorType })
      return this.getLogItems(query)
    },
    createQueryConfig(config) {
      return {
        page: this.logItems.page,
        limit: this.logItems.limit,
        dateFrom: this.dateFrom || null,
        dateTo: this.dateTo || null,
        executorType: this.executorType || null,
        ...config,
      }
    },

    getLogItems(params) {
      return this.waitRequest(async () => {
        const { data } = await this.fetchTable({ params, id: this.id }).catch(this.checkErrorsAlert)
        const limit = params.limit || 30
        const count = data?.total || 0
        if (data) {
          // this.logItems = data
          this.logItems = {
            items: data.items,
            page: data.page,
            limit,
            count,
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.code {
  white-space: pre;
}
@import '~@core/scss/vue/libs/vue-good-table.scss';
legend {
  font-size: 0.857rem !important;
  font-weight: 600;
}
</style>
