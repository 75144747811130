<template>
  <div>
    <b-card>
      <div>
        <validation-observer ref="validator" mode="eager">
          <b-form>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Fee percent" label-for="feePercent">
                  <validation-provider
                    #default="{ errors }"
                    name="feePercent"
                    rules="required|isNumeric|min_value:0|max_value:100"
                  >
                    <b-form-input
                      id="feePercent"
                      type="text"
                      maxlength="20"
                      :value="form.feePercent"
                      :state="errors.length ? false : null"
                      @input="val => onInput(val, 'feePercent', 4)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Minimal fee amount" label-for="minFeeAmount">
                  <validation-provider #default="{ errors }" name="minFeeAmount" rules="required|isNumeric|min_value:0">
                    <b-form-input
                      id="minFeeAmount"
                      type="text"
                      maxlength="20"
                      :value="form.minFeeAmount"
                      :state="errors.length ? false : null"
                      @input="val => onInput(val, 'minFeeAmount', coin.decimals)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Min transaction amount" label-for="minTransactionAmount">
                  <validation-provider
                    #default="{ errors }"
                    name="minTransactionAmount"
                    rules="required|isNumeric|min_value:0"
                  >
                    <b-form-input
                      id="minTransactionAmount"
                      type="text"
                      maxlength="20"
                      :value="form.minTransactionAmount"
                      :state="errors.length ? false : null"
                      @input="val => onInput(val, 'minTransactionAmount', coin.decimals)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Save coefficient" label-for="saveCoefficient">
                  <validation-provider #default="{ errors }" name="saveCoefficient" rules="isNumeric|min_value:0">
                    <b-form-input
                      id="saveCoefficient"
                      type="text"
                      maxlength="20"
                      :value="form.saveCoefficient"
                      :state="errors.length ? false : null"
                      @input="val => onInput(val, 'saveCoefficient', coin.decimals)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Referrer 1st lvl percent" label-for="referrerFeePercent">
                  <validation-provider #default="{ errors }" name="referrerFeePercent" rules="isNumeric|min_value:0">
                    <b-form-input
                      id="referrerFeePercent"
                      type="text"
                      maxlength="20"
                      :value="form.referrerFeePercent"
                      :state="errors.length ? false : null"
                      @input="val => onInput(val, 'referrerFeePercent', coin.decimals)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Referrer 2nd lvl percent" label-for="referrer2FeePercent">
                  <validation-provider #default="{ errors }" name="referrer2FeePercent" rules="isNumeric|min_value:0">
                    <b-form-input
                        id="referrer2FeePercent"
                        type="text"
                        maxlength="20"
                        :value="form.referrer2FeePercent"
                        :state="errors.length ? false : null"
                        @input="val => onInput(val, 'referrer2FeePercent', coin.decimals)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Action Buttons -->
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
// eslint-disable-next-line
import { required, min_value, max_value, numeric, isNumeric } from '@validations'
export default {
  name: 'FeeEdit',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      feeId: null,
      coin: {},
      form: {
        feePercent: '0',
        minFeeAmount: '0',
        minTransactionAmount: '0',
        saveCoefficient: '0',
        referrerFeePercent: '0',
        referrer2FeePercent: '0',
      },
    }
  },

  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchFeeById: 'cefiFees/fetchFeeById',
      fetchFee: 'cefiFees/fetchFee',
      updateFee: 'cefiFees/updateFee',
    }),

    initState() {
      this.registerValidator(this.$refs.validator)

      if (!this.$route.params.id) return

      this.feeId = parseInt(this.$route.params.id, 10)
      this.fetchFeeById(this.feeId)
        .then(response => {
          const {
            feePercent = '',
            minFeeAmount = '',
            minTransactionAmount = '',
            saveCoefficient = '',
            referrerFeePercent = '',
            referrer2FeePercent = '',
            cryptoCoin = {},
          } = response?.data?.data

          this.coin = cryptoCoin
          this.form = {
            feePercent: this.$decimal(feePercent)
              .toDecimalPlaces(4)
              .toString(),
            minFeeAmount: this.$decimal(minFeeAmount)
              .toDecimalPlaces(cryptoCoin.decimals || 0)
              .toString(),
            minTransactionAmount: this.$decimal(minTransactionAmount)
              .toDecimalPlaces(cryptoCoin.decimals || 0)
              .toString(),
            saveCoefficient: this.$decimal(saveCoefficient)
              .toDecimalPlaces(cryptoCoin.decimals || 0)
              .toString(),
            referrerFeePercent: this.$decimal(referrerFeePercent)
              .toDecimalPlaces(cryptoCoin.decimals || 0)
              .toString(),
            referrer2FeePercent: this.$decimal(referrer2FeePercent)
                .toDecimalPlaces(cryptoCoin.decimals || 0)
                .toString(),
          }
        })
        .catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()
      if (this.requestInProgress || !isValid) return

      this.update()
    },

    isNumber(value) {
      return !isNaN(value) && !isNaN(parseFloat(value))
    },

    update() {
      this.waitRequest(() => {
        let {
          feePercent = '',
          minFeeAmount = '',
          minTransactionAmount = '',
          saveCoefficient = '',
          referrerFeePercent = '',
          referrer2FeePercent = '',
        } = this.form

        feePercent = this.$decimal(feePercent || 0)
          .toDecimalPlaces(4)
          .toNumber()
        minFeeAmount = this.$decimal(minFeeAmount || 0)
          .toDecimalPlaces(this.coin.decimals || 0)
          .toNumber()
        minTransactionAmount = this.$decimal(minTransactionAmount || 0)
          .toDecimalPlaces(this.coin.decimals || 0)
          .toNumber()
        saveCoefficient = this.$decimal(saveCoefficient || 0)
          .toDecimalPlaces(this.coin.decimals || 0)
          .toNumber()
        referrerFeePercent = this.$decimal(referrerFeePercent || 0)
          .toDecimalPlaces(this.coin.decimals || 0)
          .toNumber()
        referrer2FeePercent = this.$decimal(referrer2FeePercent || 0)
            .toDecimalPlaces(this.coin.decimals || 0)
            .toNumber()

        return this.updateFee({
          id: this.feeId,
          form: {
            feePercent,
            minFeeAmount,
            minTransactionAmount,
            saveCoefficient,
            referrerFeePercent,
            referrer2FeePercent,
          },
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fee is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })

            this.$router.push('/fee')
            this.form = {
              feePercent: '0',
              minFeeAmount: '0',
              minTransactionAmount: '0',
              saveCoefficient: '0',
              referrerFeePercent: '0',
              referrer2FeePercent: '0',
            }
          })
          .catch(this.checkErrors)
      })
    },
    onInput(val, name, decimal) {
      if (this.isNumber(val)) {
        const amount = this.$decimal(val || 0)
        this.form[name] = amount.todp(decimal).toString()
      } else {
        this.form[name] = val
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
