<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model.trim="form.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Category"
                label-for="category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <v-select
                    v-model="form.categoryId"
                    label="name"
                    :options="category"
                    :reduce="option => option.id"
                    placeholder="set category"
                  />

                  <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Network"
                label-for="network"
              >
                <validation-provider
                  #default="{ errors }"
                  name="networks"
                  rules="required"
                >
                  <v-select
                    v-model="form.network"
                    label="name"
                    :options="networks"
                    :reduce="option => option.network"
                    placeholder="set network"
                  />

                  <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Description EN"
                label-for="descriptionEn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="descriptionEn"
                >
                  <b-form-input
                    v-model.number="form.description.en"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description EN"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Description RU"
                label-for="descriptionRu"
              >
                <validation-provider
                  #default="{ errors }"
                  name="descriptionRu"
                >
                  <b-form-input
                    v-model.number="form.description.ru"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description RU"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="App Link"
                label-for="appLink"
              >
                <validation-provider
                  #default="{ errors }"
                  name="appLink"
                >
                  <b-form-input
                    v-model.trim="form.appLink"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="App Link"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="TMA Link"
                label-for="tmaLink"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tmaLink"
                >
                  <b-form-input
                    v-model.number="form.tmaLink"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="TMA Link"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Group Link"
                label-for="groupLink"
              >
                <validation-provider
                  #default="{ errors }"
                  name="groupLink"
                >
                  <b-form-input
                    v-model.trim="form.groupLink"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Group Link"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <div class="font-weight-bold mb-1">
                Active
              </div>
              <b-form-checkbox
                v-model="form.active"
                switch
              />
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="img url"
                label-for="imgUrl"
              >
                <validation-provider
                  #default="{ errors }"
                  name="imgUrl"
                >
                  <b-form-input
                    v-model.number="form.imgUrl"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Images url"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click.prevent="save"
          >
            Save
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import vSelect from 'vue-select'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'

export default {
  name: 'DappsEdit',
  components: {
    // ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    vSelect,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      currencyId: null,
      itemDapp: '',
      form: {
        name: '',
        description: {
          en: '',
          ru: '',
        },
        imgUrl: '',
        appLink: '',
        tmaLink: '',
        groupLink: '',
        categoryId: null,
        active: true,
        network: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      item: 'dapps/item',
      category: 'dapps/category',
      networks: 'dapps/networks',
    }),
  },
  watch: {
    item() {
      this.itemDapp = this.item
      this.form = this.itemDapp
      this.form.category = this.itemDapp.category.id
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchItem: 'dapps/fetchItem',
      fetchCategory: 'dapps/fetchCategory',
      fetchNetworks: 'dapps/fetchNetworks',
      updateItem: 'dapps/updateItem',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return

      this.currencyId = this.$route.params.id
      this.fetchItem(this.currencyId)
      this.fetchCategory()
      this.fetchNetworks()
    },

    update() {
      this.waitRequest(() => this.updateItem({ id: this.currencyId, form: this.form })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'DApp is updated',
              icon: 'PlusIcon',
              variant: 'info',
            },
          })
          this.$router.push('/dapps-settings')
        })
        .catch(this.checkErrors))
    },

    async save() {
      const isValid = await this.checkIsValidForm()
      if (this.requestInProgress || !isValid) return
      this.update()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
