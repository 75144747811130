export default [
  {
    label: 'User',
    field: 'user',
  },
  {
    label: 'Asset',
    field: 'asset',
  },
  {
    label: 'Balance',
    field: 'balance',
  },
  {
    label: 'Hold',
    field: 'hold',
  },
  {
    label: 'Address',
    field: 'checkUrl',
  },
  // {
  //   label: 'Time',
  //   field: 'dataCreated',
  // },
  {
    label: 'Risk Level',
    field: 'amlRiskLevel',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
