<template>
  <b-card>
    <b-card-title class="mb-1">
      Users by country
    </b-card-title>
    <GeoChart
      :header="['Country', 'Number of users']"
      :rows="countriesData"
    />
  </b-card>
</template>
<script>
import { BCard, BCardTitle } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import GeoChart from '@/views/apps/analytics-dashboard/charts/GeoChart.vue'
import validationError from '@/mixins/validationError'

export default {
  name: 'UsersMap',
  components: { GeoChart, BCardTitle, BCard },
  mixins: [validationError],
  computed: {
    ...mapGetters({
      usersMap: 'dashboard/usersMap',
      countries: 'countries/countries',
    }),
    countriesData() {
      const countries = Object.entries(this.usersMap || {})
      return (
        countries?.reduce((acc, [id, amount]) => {
          const result = this.findCountries(id)
          if (!result) return acc
          return [...acc, [result.name, amount]]
        }, []) || []
      )
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchUsersMap: 'dashboard/fetchUsersMap',
      fetchCountries: 'countries/fetchCountries',
    }),
    initState() {
      if (!this.countries?.length) this.fetchCountries().catch(this.checkErrors)
      this.fetchUsersMap().catch(this.checkErrors)
    },
    findCountries(id) {
      return this.countries?.find(country => country.id === parseInt(id, 10))
    },
  },
}
</script>

<style lang="scss" scoped></style>
