export default [
  {
    path: '/kyc',
    name: 'kyc-dashboard',
    component: () => import('@/views/apps/kyc/index.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-dashboard',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
      ],
    },
  },
  {
    path: '/kyc/all',
    name: 'kyc-all',
    component: () => import('@/views/apps/kyc/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/progress',
    name: 'kyc-progress',
    component: () => import('@/views/apps/kyc/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/verified',
    name: 'kyc-verified',
    component: () => import('@/views/apps/kyc/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/rejected',
    name: 'kyc-rejected',
    component: () => import('@/views/apps/kyc/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/new',
    name: 'kyc-new',
    component: () => import('@/views/apps/kyc/all.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/forbidden',
    name: 'kyc-forbidden',
    component: () => import('@/views/apps/users/UsersView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/cdd-sources',
    name: 'kyc-cdd-sources',
    component: () => import('@/views/apps/kyc/cdd-sources.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-page',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/:id',
    name: 'kyc-id',
    component: () => import('@/views/apps/kyc/_id/index.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-application-view',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'KYC',
        },
      ],
    },
  },
  {
    path: '/kyc/:id/detail',
    name: 'kyc-id-detail',
    component: () => import('@/views/apps/kyc/_id/detail.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-profile-view',
      pageTitle: 'KYC',
      breadcrumb: [
        {
          text: 'Detail',
        },
      ],
    },
  },
]
