<template>
  <div
    v-if="id"
    class="mb-2"
  >
    <b-button
      v-b-toggle.collapse-1
      variant="primary"
    >
      Show logs
    </b-button>
    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <kyc-logs :id="id" />
    </b-collapse>
  </div>
</template>
<script>
import { BButton, BCollapse, VBToggle } from 'bootstrap-vue'
import KycLogs from '@/views/apps/kyc/components/logs.vue'

export default {
  name: 'KycLogsWrapper',
  components: {
    BCollapse,
    BButton,
    KycLogs,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
}
</script>
