<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex  justify-content-end flex-wrap mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-auto"
            type="button"
            variant="primary"
            @click="create"
          >
            <feather-icon
              icon="PlusIcon"
              size="14"
            />
            <span> Create dapps </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <AppTable
      :rows="dapps.items"
      :columns="columns"
      :is-loading="requestInProgress"
      :is-pagination="false"
    >
      <template #default="{ column, row, formattedRow }">
        <!-- Column: Action -->
        <span v-if="column.field === 'category'">
          {{ row.category.name }}
        </span>

        <span v-else-if="column.field === 'appLink'">
          {{ formatUrl(row.appLink) }}
        </span>

        <span
          v-else-if="column.field === 'status'"
          class="flex-center-align"
        >
          <b-badge
            :variant="row.active ? 'success' : 'info'"
            class="text-white"
          >
            {{ row.active ? 'active' : 'not active' }}
          </b-badge>
        </span>

        <div
          v-else-if="column.field === 'action'"
          class="d-flex"
        >
          <b-button
            class="mt-auto text-white ml-1"
            type="button"
            variant="primary"
            @click="edit(row.id)"
          >
            <span class="text-white"> Edit </span>
          </b-button>

          <b-button
            class="mt-auto text-white ml-1"
            type="button"
            variant="danger"
            @click="modalDeleteDapps(row.id)"
          >
            <span class="text-white"> Delete </span>
          </b-button>
        </div>
        <!-- default render cell -->
        <span
          v-else
          class="flex-center-align"
        >
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import { secondsToReadableFormat } from '@/tools/util'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import AppTable from '@/components/AppTable.vue'

import columns from './config/tableConfig'

export default {
  name: 'DappsSettings',
  components: {
    BBadge,
    AppTable,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      dapps: 'dapps/dapps',
    }),
  },
  mounted() {
    this.initState()
  },

  methods: {
    secondsToReadableFormat,
    ...mapActions({
      fetchDapps: 'dapps/fetchDapps',
      deleteItem: 'dapps/deleteItem',
    }),

    async initState() {
      await this.fetchDapps().catch(this.checkErrors)
    },

    create() {
      this.$router.push({ path: '/dapps-settings/create' })
    },

    edit(id) {
      this.$router.push({ path: `/dapps-settings/edit/${id}` })
    },
    modalDeleteDapps(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (!result.isConfirmed) return
        this.deleteDapps(id)
      })
    },

    deleteDapps(id) {
      this.deleteItem(id)
        .then(() => {
          this.initState()
        })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'DApp has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(this.checkErrors)
    },
    formatUrl(link) {
      if (!link) return ''

      if (link.length < 31) {
        return link
      }

      // const str = link
      return `${link.slice(0, 15)}...${link.slice(-15)}`
    },
  },
}
</script>
