<template>
  <b-table
    v-if="vouchers.length"
    :items="vouchers"
    :fields="voucherFields"
    bordered
  >
    <template #cell(status)="data">
      <span :class="getStatusClass(data.item.status)">
        {{ data.item.status }}
      </span>
    </template>
  </b-table>
  <h3
    v-else
    class="text-center"
  >
    Not vouchers
  </h3>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { voucherFields } from './inventoryData'

export default {
  name: 'InventoryVouchers',
  components: {
    BTable,
  },
  props: {
    vouchers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    voucherFields,
  }),
  methods: {
    getStatusClass(status) {
      return status === 'available' ? 'text-success' : 'text-muted'
    },
  },
}
</script>

<style>
</style>
