export default [
  {
    path: '/banned-users',
    name: 'banned-users',
    component: () => import('@/views/apps/banned-users/BannedUsersView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'users-user-page',
      pageTitle: 'Banned Users',
      breadcrumb: [
        {
          text: 'Banned Users',
        },
      ],
    },
  },
]
