<template>
  <div v-if="mainData">
    <KycHeader
      v-if="mainData"
      :is-change-responsible="permissions.isResponsible"
      :main-data="mainData"
      @onChange="initData"
    />
    <KycCustomer
      :is-show-view-button="permissions.isShowFullProfile"
      :main-data="mainData"
    />
    <KycLinks :main-data="mainData" />
    <ApplicantInformation
      :main-data="mainData"
      @onChange="initData"
    />
    <KycLogs :id="mainData.user.id" />
    <KycCheck
      v-for="(section, index) in kycSectionsList"
      :key="section.key"
      :description="section.description"
      :is-enable-approve-button="(isEnableAproveButton && permissions.isReview) || permissions.isEdit"
      :is-show-global-button="kycSectionsList.length - 1 === index && permissions.isReview"
      :link="section.key"
      :lists="section.lists"
      :main-data="mainData"
      :status="section.status"
      :title="section.full"
      @onChange="initData"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import KycHeader from '@/views/apps/kyc/components/header.vue'
import KycCustomer from '@/views/apps/kyc/components/customer.vue'
import KycLinks from '@/views/apps/kyc/components/links.vue'
import ApplicantInformation from '@/views/apps/kyc/components/applicantInfo.vue'
import KycCheck from '@/views/apps/kyc/components/check.vue'
import { kucSections, kycData } from '@/views/apps/kyc/config/kycTypes'
import { formattedDate } from '@/tools/util'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import KycLogs from '@/views/apps/kyc/components/logsWrapper.vue'

export default {
  name: 'KYCId',
  components: {
    KycLogs,
    KycCheck,
    ApplicantInformation,
    KycCustomer,
    KycHeader,
    KycLinks,
  },
  mixins: [waitRequest, checkErrorsAlert],
  data() {
    return {
      formattedDate,
      kucSections,
      id: this.$route.params.id,
      mainData: null,
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    permissions() {
      const result = {
        isShowFullProfile: false,
        isReview: false,
        isEdit: false,
        isResponsible: false,
        isViewFiles: false,
      }
      this.listOwnerPermissions.forEach(item => {
        switch (item) {
          case 'kyc-profile-view':
            result.isShowFullProfile = true
            break
          case 'kyc-review':
            result.isReview = true
            break
          case 'kyc-change-status':
            result.isEdit = true
            break
          case 'kyc-set-responsible':
            result.isResponsible = true
            break
          case 'kyc-view-photo':
            result.isViewFiles = true
            break
          default:
            break
        }
      })

      return result
    },
    isEnableAproveButton() {
      return this.mainData.isEnableVerify
      // if (this.mainData?.checks) {
      //   return Object.keys(this.mainData.checks).every(key => {
      //     const target = this.mainData.checks[key]
      //     if ((target?.status || target?.[0]?.status) === 'verified') return true
      //     return false
      //   })
      // }
      // return false
    },
    kycSectionsList() {
      const getHeaderSections = item => {
        if (!Array.isArray(item) || !item.length) {
          return null
        }
        const target = item[0]

        const list = []

        if (target.documentType) {
          list.push({
            title: this.$t('kyc.applicantInfo.form.documentType'),
            value: this.convertCamelCaseToCapitalizedSpaces(target.documentType),
            type: 'default',
          })
        }
        if (target.issuingCountry) {
          list.push({
            title: this.$t('kyc.applicantInfo.issuingCountry'),
            value: target.issuingCountry,
            type: 'default',
          })
        }
        if (!list.length) {
          return null
        }
        return ({
          list,
          type: 'default',
        })
      }
      let status = null
      
      if (!this.mainData.checks) return []
      
      return this.kucSections.map(section => {
        const checks = this.mainData.checks[section.key]

        if (!checks) return section
        const lists = []

        const header = getHeaderSections(checks)
        if (header) lists.push(header)

        const bottom = {
          list: [],
          type: 'default',
        }
        if (checks?.[0]) {
          status = checks?.[0]?.status || this.$t('notFound')
          bottom.list.push({
            title: this.$t('status'),
            value: status,
            type: 'status',
          })
        } else if (checks?.status) {
          status = checks?.status || this.$t('notFound')
          bottom.list.push({
            title: this.$t('status'),
            value: status,
            type: 'status',
          })
        }
        
        if (checks) {
          if (!['cdd', 'geo'].includes(section.key)) {
            if (!checks.length) return section
            if (this.permissions.isViewFiles) {
              checks.forEach(list => lists.push({
                list: {
                  ...list,
                  createdAt: list.createdAt ? this.formattedDate(list.createdAt, kycData) : '',
                },
                type: 'files',
              }))
            }
            if (checks[0]?.reason) {
              bottom.list.push({
                title: this.$t('reason'),
                value: this.convertCamelCaseToCapitalizedSpaces(checks[0].reason),
                type: 'default',
              })
            }
            if (checks[0]?.occupation) {
              bottom.list.push({
                title: this.$t('occupation'),
                value: this.convertSnakeCaseToCapitalizedSpaces(checks[0].occupation),
                type: 'default',
              })
            }
          } else {
            if (checks.baseName) {
              bottom.list.push({
                title: this.$t('coincidences'),
                value: checks.baseName,
                type: 'default',
              })
            }
            if (checks.data) {
              bottom.list.push({
                title: this.$t('data'),
                value: checks.data,
                type: 'data',
              })
            }
            if (checks.citizenship) {
              bottom.list.push({
                title: this.$t('citizenship'),
                value: checks.citizenship,
                type: 'default',
              })
            }
            if (checks.phone) {
              bottom.list.push({
                title: this.$t('phone'),
                value: checks.phone,
                type: 'default',
              })
            }
            if (checks.coordinates) {
              bottom.list.push({
                title: this.$t('coordinates'),
                value: checks.coordinates,
                type: 'data',
              })
            }
            if (checks.phoneCountry) {
              bottom.list.push({
                title: this.$t('phoneCountry'),
                value: checks.phoneCountry,
                type: 'default',
              })
            }
            if (checks.residentship) {
              bottom.list.push({
                title: this.$t('residentship'),
                value: checks.residentship,
                type: 'default',
              })
            }
          }
        }
        if (section?.note) {
          bottom.list.push({
            title: this.$t('note'),
            value: section.note,
            type: 'note',
          })
        }
        
        const isUserApplicant = this.mainData.responsible?.id === this.$store.getters['auth/admin'].id
        const isCanAction = section.api && (checks?.[0]?.id || checks?.id)
        const isPermission = this.permissions.isReview || this.permissions.isEdit
        const isChangeable = (isUserApplicant && !['verified', 'reject'].includes(checks?.[0]?.status || checks?.status)) || (this.permissions.isEdit && !['reject'].includes(checks?.[0]?.status || checks?.status))

        if (isPermission && isCanAction && isChangeable) {
          bottom.list.push({
            title: '',
            value: {
              key: section.api,
              id: checks?.[0]?.id || checks?.id,
              requestId: this.mainData.id,
              title: this.$t(section.short),
            },
            type: 'actions',
          })
        }

        lists.push(bottom)
        return {
          ...section,
          status,
          lists,
        }
      })
    },

  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions({
      fetchInfo: 'kyc/fetchInfo',
    }),
    convertCamelCaseToCapitalizedSpaces(str) {
      console.log('convertCamelCaseToCapitalizedSpaces', str)
      return typeof str === 'string' ? str.replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^\w/, char => char.toUpperCase()) : str
    },
    convertSnakeCaseToCapitalizedSpaces(str) {
      console.log('convertSnakeCaseToCapitalizedSpaces', str)
      return str.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^\w/, char => char.toUpperCase())
    },
    initData() {
      return this.waitRequest(async () => {
        const { data } = await this.fetchInfo(this.id).catch(this.checkErrorsAlert)
        if (data) this.mainData = data
      })
    },
  },
}
</script>
